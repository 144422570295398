<template>
  <div>
<div class="modal fade ConnectModalStyles" id="UpdateIntegrationModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" v-bind="$attrs">
  
  <div class="modal-dialog modal-lg " >
      <div class="modal-content " id="UpdateIntegrationModalContent">
       

          <!-- Modal Content -->
          

            <div class="" >
              <div class="container px-4  pb-1" >
                 <div class="row  px-3 mt-2 py-3 d-flex justify-content-between align-items-center">
              <div>
                  <h4 class="font-weight-bold" style="color:#18191C">Update {{integration.title}}</h4>
              </div>
              <div>
                  <button 
                  @click="saveData"
          class="outline-none  outline-none border-0 px-3 mr-2 py-2 text-white"
          style="cusrsor: pointer;border-radius:6px;background:#4d1b7e;">
            <span v-if="!saveLoading">Save</span>
              <div
                v-else
                class="spinner-border spinner-border-sm"
                role="status"
              >
                <span class="sr-only">Loading...</span>
              </div></button>
                  <button 
                  @click="CloseModal"
          class="outline-none  outline-none border-0 px-3 py-2 text-white"
          style="cusrsor: pointer;border-radius:6px;background:#B3AFB6;">Cancel</button>
                 
              </div>
          </div>

                <div class="">
                   <div class="AppFields mt-5" v-if="appFields.length">
                    
            <div class="mb-4" v-for="(field, index) in appFields" :key="index">
              <div class="inputContainer">
                <p class="fieldTitle">{{ field.title }}</p>
                <div class="textContainer" v-if="field.type == 'text'">
                  <div class="TextInput d-flex align-items-center py-2">
                    <input
                      type="text"
                      class="mx-2 flex-grow-1"
                      v-model="field.value"
                    />
                  </div>
                </div>
                <div
                  class="toggleContainer"
                  v-if="field.type == 'toggle_switch'"
                >
                  <b-form-checkbox
                    v-model="field.value"
                    name="check-button"
                    class="ml-2"
                    switch
                  >
                  </b-form-checkbox>
                </div>
              </div>
            </div>
          </div>
                </div>
          

          
              </div>
            </div>
          
        
      </div>
    </div>
</div>
  </div>
</template>

<script>
import axios from 'axios'
import $ from 'jquery'
export default {
    props: ["integration"],
    data(){
        return{
              appFields: [],
              saveLoading:false
        }
    },
    
    computed:{
      
    },
methods:{
OpenModal(){
        $('#UpdateIntegrationModal').modal('show')
        this.appFields = JSON.parse(this.integration.app_fields)
    },
     
    CloseModal(){
         $('#UpdateIntegrationModal').modal('hide')
    },

    handleCloseModal(){
      this.CloseModal()
    },
     getSaveConnectionPayload(arr,field){
      let value 
       for (const object of arr) {
        if (object.key == field) {
             value = object.value;
           
        }
    }

      if( typeof value != "undefined" ){
          if(value != ''){
            return value
          } else {
              return field == 'full_status' ? false : ''
          }
      } else{
        return field == 'full_status' ? false : ''
      }
    },
    async saveData() {
      try {
        this.saveLoading = true
        let data = {
           id : this.integration.connection_id,
           app_id : this.integration.id,
            api_key:this.getSaveConnectionPayload(this.appFields , 'api_key'),
             list_key: this.getSaveConnectionPayload(this.appFields, 'list_key'),
             other_key:this.getSaveConnectionPayload(this.appFields, 'other_key'),
             full_status:this.getSaveConnectionPayload(this.appFields, 'full_status')
        };

        const response = await axios.post(`/integrationConnectionSave`, data);
        if (response.status == 200 ) {
          this.$toasted.show("Integration updated!", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
          this.$emit("updateIntegration");
          this.CloseModal()
        } else {
          this.$toasted.show('Something went wrong', {
            theme: "toasted-primary",
            type: "error",
            position: "bottom-center",
            duration: 2000,
          });
        }
      } catch (error) {
        if (error) {
          this.$toasted.show("Error occured ", {
            theme: "toasted-primary",
            type: "error",
            position: "bottom-center",
            duration: 2000,
          });
        }
        throw error;
      } finally{
        this.saveLoading = false
      }
    },
},
}
</script>

<style >
#UpdateIntegrationModalContent{
    border-radius:20px;
}
.QuestionsDiv{
    height:100%;
}

.TextInput {
  border: 0.8px solid #b3afb6;
  box-sizing: border-box;
  border-radius: 6px;
}

.TextInput input {
  background: transparent;
  border: none;
  outline: none;
}
.TextInput input:focus {
  border: none;
  outline: none;
}


  /* switch Css */
 /* .custom-switch .custom-control-input ~ .custom-control-label::after {
  left: calc(-2.25rem + 0px) !important;
  background: #6d6b6d !important;
}
 .custom-switch
  .custom-control-input:checked
  ~ .custom-control-label[data-v-2c6c3e7d]::after,
.custom-switch .custom-control-input ~ .custom-control-label::after {
  height: 16px !important;
  width: 16px !important;
  top: 4px !important;
}
.custom-control-input:checked ~ .custom-control-label::before {
  background-color: #e0dee2 !important;
  border-color: #ced3d7 !important;
}
 .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #ffa201 !important;
  border-color: #ced3d7 !important;
} */


</style>